<script>
import VxAppBar from "@/components/vx/VxAppBar";
import VxAppBarSearch from "@/components/vx/VxAppBarSearch";
import EmployeeAction from "@/components/employees/EmployeeAction.js";

export default {
  name: "TextingAppBar",
  components: {
    VxAppBar,
    VxAppBarSearch,
    EmployeeAction,
  },
  props: {
    filter: {
      type: String,
      default: undefined,
    },
    parsedFilter: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    handleClick() {
      this.$router.push({ name: "texting.setup" });
    },
  },
};
</script>

<template>
  <VxAppBar title="Texting" mobile-search :filter="filter">
    <template #search="{ back }">
      <VxAppBarSearch
        title="Texting"
        :filter="filter"
        :parsed-filter="parsedFilter"
        @back="back"
      />
    </template>
    <template #rightMenuItems>
      <EmployeeAction
        v-slot="{ handleAction, isDisabled, listeners }"
        check-manager
      >
        <v-list-item ripple v-on="listeners" @click="handleAction(handleClick)">
          <v-list-item-title :class="{ 'text--disabled': isDisabled }"
            >Texting Settings</v-list-item-title
          >
        </v-list-item>
      </EmployeeAction>
    </template>
  </VxAppBar>
</template>
